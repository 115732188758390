import Footer from 'components/layout/Footer'
import PreviewNotice from 'components/layout/PreviewNotice'
import BodyRenderer from 'components/renderers/BodyRenderer'
import HeaderContainer from 'components/renderers/HeaderContainer'
import HeroRenderer from 'components/renderers/HeroRenderer'
import PageSeo from 'components/seo/PageSeo'
import Env from 'Env'
import { TopPosterModel } from 'models/body/TopPosterModel'
import { AppInfoModel } from 'models/config/AppInfoModel'
import { MenuModel } from 'models/menu/MenuModel'
import { PageModel } from 'models/page/PageModel'
import { GetStaticProps, NextPage } from 'next'
import Head from 'next/head'
import React, { Fragment } from 'react'
import { ApiPromise } from 'services/ApiPromise'
import { Converters } from 'services/Converters'

type Props = {
  page: PageModel
  menu: MenuModel
  footer: MenuModel
  isPreview: boolean
  posters: {
    data: TopPosterModel[]
  }
  appConfig: AppInfoModel
}

const IndexPage: NextPage<Props> = (props) => {
  const pageAttributes = props.page.data.attributes
  // const posters = props.posters.data
  return (
    <Fragment>
      <Head>
        <link rel="canonical" href={Env.SiteUrl} />
      </Head>
      <PageSeo title={pageAttributes.title} seo={pageAttributes.seo} />
      {props.isPreview ? <PreviewNotice /> : null}

      <HeaderContainer posters={props.posters} menu={props.menu}/>
      
      {pageAttributes.hero.map((hero, index) => {
        return <HeroRenderer key={index} data={hero} />
      })}
      {pageAttributes.body.map((section, index) => {
        return <BodyRenderer key={index} data={section} />
      })}
      <Footer data={props.footer} />
    </Fragment>
  )
}

export const getStaticProps: GetStaticProps = async (context) => {
  const isPreview = context.preview
  const allPromise = ApiPromise.Page.GetHomePageWithHeaderFooter(isPreview)
  const [menuPromise, pagePromise, footerPromise, postersPromise] = await Promise.all(allPromise)
  const [menu, page, footer, posters] = Converters.KeysConvert.ConvertAllToLower([menuPromise.data, pagePromise.data, footerPromise.data, postersPromise.data])
  const appConfig = await ApiPromise.Layout.AppConfig()
  return { props: { page, menu, footer, posters, appConfig, isPreview: isPreview ? true : false }, revalidate: Env.PropsRevalidateTime }
}

export default IndexPage
